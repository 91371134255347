import * as React from "react";
import { Component } from 'react';
import  { Container, Row, Col} from 'react-bootstrap';

class Content extends Component {
  render() {
    return (
      <div className={`${this.props.show ? 'd-block' : 'd-none'}`}>
        <Container fluid className="hero living-mosaic" />
        <Container className="care-continuum">
          <Row>
            <Col xs={9} lg={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                <h1 className="sub-title">The Living Mosaic</h1>
                <h1 className="title">The community's inspirations for helping end the epidemic</h1>
              </div>
              <iframe frameBorder="0 " width="100% " height="800 " scrolling="yes " src="https://mosaic.gileadataids2020.com " allow="camera *;microphone *;encrypted-media * " data-dashlane-frameid="6649 "></iframe>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Content;